import MaterialTable from "material-table";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	Row,
} from "shards-react";
import doctorsApi from "../api/doctors";
import PageTitle from "../components/common/PageTitle";
import Featching from "../components/featching/featching";
import { doctorsAdded } from "../store/doctors-reducer";
import { formatDoctor } from "../utils/formatResponse";

const Doctors = () => {
	const columns = [
		{ title: "Name", field: "name" },
		{
			title: "Specialization",
			field: "specialization",
			width: "80%",
		},

		{
			title: "Email",
			field: "email",
		},
		{
			title: "Phone Number",
			field: "phone",
		},
		{
			title: "Status",
			field: "status",
		},
	];

	const [data, setData] = useState([]);

	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	useEffect(() => {
		setLoading(true);
		doctorsApi.getAll(onSuccess, onFaileure);
		// eslint-disable-next-line
	}, []);

	const onFaileure = (error) => {
		console.log("This Failed : ", error);
	};

	const onSuccess = (res) => {
		setLoading(false);
		dispatch(doctorsAdded(res.data.filter((user) => user.role !== "admin")));
		setData(formatDoctor(res.data.filter((user) => user.role !== "admin")));
	};

	const activateDoctor = (id) => {
		console.log("this is id", id);
		setLoading(true);
		doctorsApi.activate(id, activationSuccess, onFaileure);
	};
	const deactivateDoctor = (id) => {
		setLoading(true);
		doctorsApi.deactivate(id, activationSuccess, onFaileure);
	};

	const activationSuccess = () => {
		doctorsApi.getAll(onSuccess, onFaileure);
	};

	return (
		<Container fluid className='main-content-container px-4'>
			{/* Page Header */}
			<Row noGutters className='page-header py-4'>
				<PageTitle sm='4' title='Doctors' className='text-sm-left' />
			</Row>
			<ToastContainer />

			<Row>
				<Col>
					<Card small className='mb-4 overflow-hidden'>
						<CardHeader
							className='bg-dark'
							style={{ display: "flex", justifyContent: "space-between" }}
						>
							<h6 className='m-0 text-white'>All Doctors</h6>
							<Button
								theme='success'
								onClick={() => {
									navigate("/admin/create-profile");
								}}
							>
								<span style={{ marginRight: "10px" }}>
									<i className='material-icons'>add</i>
								</span>
								Add New Doctor
							</Button>
						</CardHeader>
						<CardBody className='bg-light p-0 table__body'>
							<MaterialTable
								title={""}
								options={{
									exportButton: true,
									actionsColumnIndex: -1,
									search: true,
								}}
								columns={columns}
								data={data}
								actions={[
									{
										icon: "edit",
										tooltip: "Edit Doctor",
										onClick: (event, rowData) => {
											navigate(`/admin/edit-doctor/${rowData._id}`);
										},
									},
									{
										icon: "book",
										tooltip: "View Detail",
										onClick: (event, rowData) => {
											navigate(`/admin/doctors-detail/${rowData._id}`);
										},
									},
									(rowData) => ({
										icon: rowData.status === "Active" ? "dangerous" : "check",
										tooltip:
											rowData.status === "Active" ? "Deactivate" : "Activate",
										onClick: (event, rowData) => {
											rowData.status === "Active"
												? deactivateDoctor(rowData._id)
												: activateDoctor(rowData._id);
										},
										disabled: rowData.active,
									}),
								]}
							/>
							{loading && <Featching />}
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default Doctors;
