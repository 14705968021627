import PropTypes from "prop-types";
import React, { useState } from "react";
import { toast } from "react-toastify";
import {
	Button,
	Card,
	CardHeader,
	Col,
	Form,
	FormInput,
	ListGroup,
	ListGroupItem,
	Row,
} from "shards-react";
import departmentsApi from "../../api/departments";

const CreateDepartment = ({ fetch, setFetch }) => {
	const [department, setDepartment] = useState({
		deptName: {
			en: "",
			am: "",
		},
		deptDetail: {
			en: "",
			am: "",
		},
	});
	const [loading, setLoading] = useState(false);
	const handleSubmit = () => {
		setLoading(false);
		toast.promise(departmentsApi.add(department, onSuccess, onFaileure), {
			pending: "proccessing ...",
		});
	};

	const onSuccess = () => {
		setLoading(false);
		setFetch(!fetch);
		setDepartment({
			deptName: {
				en: "",
				am: "",
			},
			deptDetail: {
				en: "",
				am: "",
			},
		});
	};
	const onFaileure = (error) => {
		console.log(error);
		setLoading(false);
	};

	const handleChange = (e) => {
		if (e.target.name.slice(-2) === "en") {
			setDepartment({
				...department,
				[e.target.name.slice(0, -3)]: {
					am: department[e.target.name.slice(0, -3)].am,
					en: e.target.value,
				},
			});
		} else {
			setDepartment({
				...department,
				[e.target.name.slice(0, -3)]: {
					en: department[e.target.name.slice(0, -3)].en,
					am: e.target.value,
				},
			});
		}
	};

	return (
		<Card small className='mb-4'>
			<CardHeader className='border-bottom'>
				<h6 className='m-0'>Create Department</h6>
			</CardHeader>
			<ListGroup flush>
				<ListGroupItem className='p-3'>
					<Row style={{ padding: "30px 0" }}>
						<Col>
							<Form
								method='post'
								onSubmit={(e) => {
									e.preventDefault();
									handleSubmit();
								}}
							>
								<Row form>
									<Col md='3' className='form-group'>
										<FormInput
											md='6'
											requ
											id='feDepartment'
											placeholder='Department Name'
											name='deptName.en'
											value={department.deptName.en}
											onChange={handleChange}
											required
											lable='en'
										/>
									</Col>
									<Col md='3' className='form-group'>
										<FormInput
											md='6'
											requ
											id='feDepartment'
											placeholder='Department Name'
											name='deptDetail.en'
											value={department.deptDetail.en}
											onChange={handleChange}
											required
											lable='en'
										/>
									</Col>
									<Col md='3' className='form-group'>
										<FormInput
											md='6'
											requ
											id='feDepartment'
											placeholder='የቡድን ስም'
											name='deptName.am'
											value={department.deptName.am}
											onChange={handleChange}
											required
											lable='en'
										/>
									</Col>
									<Col md='3' className='form-group'>
										<FormInput
											md='6'
											requ
											id='feDepartment'
											placeholder='የቡድን መግለጫ'
											name='deptDetail.am'
											value={department.deptDetail.am}
											onChange={handleChange}
											required
											lable='en'
										/>
									</Col>
									<Col md='4' className='form-group'>
										<Button theme='accent' disabled={loading} type='submit'>
											Create Department
										</Button>
									</Col>
								</Row>
							</Form>
						</Col>
					</Row>
				</ListGroupItem>
			</ListGroup>
		</Card>
	);
};

CreateDepartment.propTypes = {
	/**
	 * The component's title.
	 */
	title: PropTypes.string,
};

CreateDepartment.defaultProps = {
	title: "Account Details",
};

export default CreateDepartment;
