import { apiCall, apiFetch } from "./ApiCall";
const appointmentApi = {
	getRange: (onSuccess, onFailure) =>
		apiFetch("get", "/appointment-time-settings", onSuccess, onFailure),
	updateRange: (data, onSuccess, onFailure) =>
		apiCall("post", data, `/appointment-time-settings`, onSuccess, onFailure),
	getAll: (onSuccess, onFailure) =>
		apiFetch("get", "/appointment", onSuccess, onFailure),
	deleteOne: (id, onSuccess, onFailure) =>
		apiFetch("delete", `/appointment/${id}`, onSuccess, onFailure),
	getByPatient: (email, onSuccess, onFailure) =>
		apiFetch(
			"get",
			`/appointment/get-by-patient/${email}`,
			onSuccess,
			onFailure
		),
	makeAppointment: (data, onSuccess, onFailure) =>
		apiCall("post", { ...data }, "/appointment", onSuccess, onFailure),
	updateAppointment: (data, id, onSuccess, onFailure) =>
		apiCall("put", { ...data }, `/appointment/${id}`, onSuccess, onFailure),
	getByDoctor: (id, onSuccess, onFailure) =>
		apiFetch("get", `/appointment/get-by-doctor/${id}`, onSuccess, onFailure),
	getDateByDoctor: (id, onSuccess, onFailure) =>
		apiFetch("get", `/doctor/${id}/available-dates`, onSuccess, onFailure),
	getTimeByDoctor: (id, date, onSuccess, onFailure) =>
		apiFetch(
			"get",
			`/doctor/${id}/available-hours?date=${date}`,
			onSuccess,
			onFailure
		),
	deleteAppointment: (id, onSuccess, onFailure) =>
		apiFetch("delete", `/appointment/${id}`, onSuccess, onFailure),
};

export default appointmentApi;

// add: (data, onSuccess, onFailure) =>
// 		apiCall("post", data, "/doctor", onSuccess, onFailure),
// deleteOne: (id, onSuccess, onFailure) =>
// 		apiFetch("delete", `/doctor/${id}`, onSuccess, onFailure),
// 	getAppointment: (id, onSuccess, onFailure) =>
// 		apiFetch("get", `/appointment/get-by-doctor/${id}`, onSuccess, onFailure),
