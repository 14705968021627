import { formatDate } from "./formatDate";

export const formatDoctor = (doctor) => {
	let temp = [];
	doctor.map((doctor) =>
		temp.push({
			_id: doctor._id,
			name: `${doctor.firstName.en} ${doctor.lastName.en}`,
			email: doctor.email,
			phone: doctor.phoneNumber,
			specialization: doctor.specialization,
			status: doctor.status.charAt(0).toUpperCase() + doctor.status.slice(1),
		})
	);
	return temp;
};

export const formatPatient = (doctor) => {
	let temp = [];
	doctor.map((doctor) =>
		temp.push({
			_id: doctor._id,
			name: `${doctor.firstName} ${doctor.lastName}`,
			email: doctor.email,
			phone: doctor.phoneNumber,
		})
	);
	return temp;
};

export const formatDepartment = (department) => {
	let temp = [];
	department.map((dept) =>
		temp.push({
			_id: dept._id,
			deptNameEn: dept.deptName.en,
			deptNameAm: dept.deptName.am,
			descriptionEn: dept.deptDetail.en,
			descriptionAm: dept.deptDetail.am,

			status: dept.status.charAt(0).toUpperCase() + dept.status.slice(1),
		})
	);
	return temp;
};

export const formatServices = (services) => {
	let temp = [];
	services.map((dept) =>
		temp.push({
			_id: dept._id,
			titleEn: dept.title.en,
			titleAm: dept.title.am,
			aboutEn: dept.about.en,
			aboutAm: dept.about.en,
			category: dept.category,
		})
	);
	return temp;
};
export const formatLanguages = (languages) => {
	let temp = [];
	languages.map((lang) =>
		temp.push({
			_id: lang._id,
			language: lang.title.charAt(0).toUpperCase() + lang.title.slice(1),
			// status: lang.status.charAt(0).toUpperCase() + lang.status.slice(1),
		})
	);
	return temp;
};
export const formatAddress = (address) => {
	let temp = [];
	address.map((ad) =>
		temp.push({
			_id: ad._id,
			language: ad.city.charAt(0).toUpperCase() + ad.city.slice(1),
			// status: lang.status.charAt(0).toUpperCase() + lang.status.slice(1),
		})
	);
	return temp;
};

export const formatAppointments = (patients) => {
	let temp = [];
	patients.map((patient) =>
		temp.push({
			_id: patient._id,
			name: `${patient.patientFirstName} ${patient.patientLastName}`,
			reasonForVisit: patient.reasonForVisit,
			gender: patient.patientGender,
			date: formatDate(new Date(patient.appointmentDate)),
			time: `${patient.appointmentTime.hour}:
			${
				patient.appointmentTime.minute !== 0
					? patient.appointmentTime.minute
					: "00"
			}`,
		})
	);
	return temp;
};

export const formatAppointmentAdmin = (doctors, appointments) => {
	let temp = [];
	// eslint-disable-next-line
	appointments.map((appt) => {
		let doc = doctors.find((doc) => doc._id === appt.doctor);
		let docId = appt.doctor;
		let patientName = `${appt.patientFirstName}  ${appt.patientLastName}`;
		let reason = appt.reasonForVisit;
		doc &&
			temp.push({
				_id: appt._id,
				doctor: `${doc.firstName.en}  ${doc.lastName.en}`,
				patient: patientName,
				reason: reason,
				docId: docId,
				date: formatDate(new Date(appt.appointmentDate)),
				time: `${appt.appointmentTime.hour} : ${
					appt.appointmentTime.minute === 0 ? "00" : appt.appointmentTime.minute
				}`,
			});
	});

	return temp;
};
