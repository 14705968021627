import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CreatableSelect from "react-select/creatable";
import {
	Button,
	Card,
	CardHeader,
	Col,
	Form,
	FormInput,
	FormSelect,
	FormTextarea,
	ListGroup,
	ListGroupItem,
	Row,
} from "shards-react";
import departmentsApi from "../../api/departments";
import { departmentsAdded } from "../../store/departments-reducer";
import { formatDepartment } from "../../utils/formatResponse";
const UserAccountDetails = ({ user, title, setUser, handleSubmit }) => {
	const [departments, setDepartments] = useState([]);

	const admin = useSelector((state) => state.user);

	const dispatch = useDispatch();

	const languages = useSelector((state) => state.language);
	const locations = useSelector((state) => state.location);

	useEffect(() => {
		departmentsApi.getAll(fetchSuccess, onFaliure);

		// eslint-disable-next-line
	}, [fetch]);

	const onFaliure = (error) => {
		console.log(error.message);
	};
	const fetchSuccess = (res) => {
		dispatch(departmentsAdded(res.data.departments));
		setDepartments(formatDepartment(res.data.departments));
	};

	const languageChange = (newValue, actionMeta) => {
		let temp = [];
		console.log("this is rthe new value", newValue);
		newValue.map((data) => {
			if (data.__isNew__) {
				return temp.push({ title: data.value.toLowerCase() });
			} else return temp.push(data._id);
		});
		setUser({ ...user, languages: temp });
	};

	const locationChange = (newValue, actionMeta) => {
		console.log("ola user", user, newValue.lable);
		if (newValue.__isNew__) {
			setUser({ ...user, address: { city: newValue.value.toLowerCase() } });
		} else setUser({ ...user, address: newValue._id });
	};

	return (
		<Card small className='mb-4'>
			<CardHeader className='border-bottom'>
				<h6 className='m-0'>{title}</h6>
			</CardHeader>

			<ListGroup flush>
				<ListGroupItem className='p-3'>
					<Row>
						<Col>
							<Form>
								<Row form>
									{/* First Name */}
									<Col md='6' className='form-group'>
										<label htmlFor='feFirstName'>First Name</label>
										<FormInput
											id='feFirstName'
											placeholder='First Name'
											value={user.firstName.am}
											onChange={(e) =>
												setUser({
													target: {
														value: {
															am: user.firstName.am,
															en: e.target.value,
														},
														name: "firstName",
													},
												})
											}
											required
										/>
									</Col>
									{/* Last Name */}
									<Col md='6' className='form-group'>
										<label htmlFor='feLastName'>Last Name</label>
										<FormInput
											id='feLastName'
											placeholder='Last Name'
											value={user.lastName.en}
											onChange={(e) =>
												setUser({
													target: {
														value: {
															am: user.lastName.am,
															en: e.target.value,
														},
														name: "firstName",
													},
												})
											}
										/>
									</Col>

									<Col md='6' className='form-group'>
										<label htmlFor='feFirstName'>የመጀመሪያ ስም</label>
										<FormInput
											id='feFirstName'
											placeholder='የመጀመሪያ ስም'
											value={user.firstName.am}
											onChange={(e) =>
												setUser({
													target: {
														value: {
															en: user.firstName.en,
															am: e.target.value,
														},
														name: "firstName",
													},
												})
											}
											required
										/>
									</Col>
									{/* Last Name */}
									<Col md='6' className='form-group'>
										<label htmlFor='feLastName'>ያባት ስም</label>
										<FormInput
											id='feLastName'
											placeholder='
											ያባት ስም'
											value={user.lastName.am}
											onChange={(e) =>
												setUser({
													target: {
														value: {
															en: user.lastName.en,
															am: e.target.value,
														},
														name: "firstName",
													},
												})
											}
										/>
									</Col>
								</Row>
								<Row form>
									{/* Email */}
									<Col md='6' className='form-group'>
										<label htmlFor='feEmail'>Email</label>
										<FormInput
											type='email'
											id='feEmail'
											placeholder='Email Address'
											value={user.email}
											disabled
											autoComplete='email'
											onChange={(e) => {
												setUser({ ...user, email: e.target.value });
											}}
										/>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='feUsername'>Username</label>
										<FormInput
											type='text'
											id='feUsername'
											placeholder='UserName'
											value={user.userName}
											onChange={(e) => {
												setUser({ ...user, userName: e.target.value });
											}}
											disabled
										/>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='fePhoneNumber'>Phone Number</label>
										<FormInput
											id='fePhoneNumber'
											placeholder='phone'
											value={user.phoneNumber}
											onChange={(e) => {
												setUser({ ...user, phoneNumber: e.target.value });
											}}
										/>
									</Col>

									<Col md='6' className='form-group'>
										<label htmlFor='feEmploye'>Employmant Type</label>
										<FormSelect
											id='feEmploye'
											value={user.employmentType}
											onChange={(e) => {
												setUser({
													...user,
													employmentType: e.target.value,
												});
											}}
										>
											<option>full-time</option>
											<option>part-time</option>
										</FormSelect>
									</Col>

									<Col md='6' className='form-group'>
										<label htmlFor='feDepartment'>Department</label>
										<FormSelect
											id='feDepartment'
											value={user.department}
											onChange={(e) => {
												setUser({
													...user,
													department: e.target.value,
												});
											}}
										>
											{departments.map((department) => (
												<option value={department._id}>
													{department.deptNameEn}
												</option>
											))}
										</FormSelect>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='feGender'>Gender</label>
										<FormSelect
											id='feGender'
											value={user.gender}
											onChange={(e) => {
												setUser({ ...user, gender: e.target.value });
											}}
										>
											<option value='male'>Male</option>
											<option value='female'>Female</option>
										</FormSelect>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='feSpecialization'>Specialization</label>
										<FormInput
											type='text'
											id='feSpecialization'
											placeholder='specialization'
											value={user.specialization}
											onChange={(e) => {
												setUser({
													...user,
													specialization: e.target.value,
												});
											}}
										/>
									</Col>

									<Col md='6' className='form-group'>
										<label htmlFor='feAge'>Birth date</label>
										<FormInput
											id='feAge'
											type='date'
											placeholder='age'
											value={new Date()}
											onChange={(e) => {
												setUser({ ...user, birthDate: e.target.value });
											}}
										/>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='feAge'>City</label>
										<CreatableSelect
											isClearable
											onChange={locationChange}
											options={locations}
											defaultValue={locations.find(
												(singleLocation) => singleLocation._id === user.address
											)}
										/>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='feAge'>Languages</label>
										<CreatableSelect
											isClearable
											isMulti
											onChange={languageChange}
											options={languages}
											// defaultValue={languages.filter((singleLang) =>
											// 	user.languages.join().includes(singleLang._id)
											// )}
										/>
									</Col>
									{admin[0] && admin[0].role === "admin" && (
										<Col md='6' className='form-group'>
											<label htmlFor='feAge'>Role</label>
											<FormSelect
												id='feGender'
												value={user.role}
												onChange={(e) => {
													setUser({ ...user, role: e.target.value });
												}}
											>
												<option value='admin'>Admin</option>
												<option value='staff'>Staff</option>
											</FormSelect>
										</Col>
									)}
									{/* <Col md='12' className='form-group'>
										<label htmlFor='feAge'>Bio</label>
										<FormTextarea
											id='feAge'
											type='date'
											placeholder='bio'
											value={user.bio}
											onChange={(e) => {
												setUser({ ...user, bio: e.target.value });
											}}
										/>
									</Col> */}
									<Col md='12' className='form-group'>
										<label htmlFor='feTextArea'>Bio</label>
										<FormTextarea
											type='textarea'
											id='feTextArea'
											placeholder='bio'
											name='bio'
											value={user.bio && user.bio.en}
											onChange={(e) =>
												setUser({
													...user,
													bio: {
														am: user.bio.am,
														en: e.target.value,
													},
												})
											}
											hasValue={true}
										/>
									</Col>
									<Col md='12' className='form-group'>
										<label htmlFor='feTextArea'>የህይወት ታሪክ</label>
										<FormTextarea
											type='textarea'
											id='feTextArea'
											placeholder='bio'
											name='bio'
											value={user.bio && user.bio.am}
											onChange={(e) =>
												setUser({
													...user,
													bio: {
														en: user.bio.en,
														am: e.target.value,
													},
												})
											}
											hasValue={true}
										/>
									</Col>
								</Row>
								<Button theme='accent' onClick={handleSubmit}>
									Update Account
								</Button>
							</Form>
						</Col>
					</Row>
				</ListGroupItem>
			</ListGroup>
		</Card>
	);
};

UserAccountDetails.propTypes = {
	/**
	 * The component's title.
	 */
	title: PropTypes.string,
};

UserAccountDetails.defaultProps = {
	title: "Account Details",
};

export default UserAccountDetails;
