import { apiCall, apiFetch } from "./ApiCall";
const servicesApi = {
	add: (data, onSuccess, onFailure) =>
		apiCall("post", data, "/service", onSuccess, onFailure),
	getAll: (onSuccess, onFailure) =>
		apiFetch("get", `/service/`, onSuccess, onFailure),
	updateOne: (data, id, onSuccess, onFailure) =>
		apiCall("put", data, `/service/${id}`, onSuccess, onFailure),
	deleteOne: (id, onSuccess, onFailure) =>
		apiFetch("delete", `/service/${id}`, onSuccess, onFailure),
};

export default servicesApi;

// updateOne: (data, id, onSuccess, onFailure) =>
// 		apiCall("put", data, `/supplier/${id}`, onSuccess, onFailure),
