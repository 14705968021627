import React from "react";
// Layout Types
import { DefaultLayout } from "./layouts";
import NormalLayout from "./layouts/Normal";
import ComponentsOverview from "./views/ComponentsOverview";
import DocAppointments from "./views/DocAppointments";
// Route Views
import DocOverview from "./views/DocOverview";
import DoctorSchedule from "./views/DoctorSchedule";
import Errors from "./views/Errors";
import StockLog from "./views/StockLog";
import DocProfile from "./views/Profile";

import SpecialDays from "./views/SpecialDays";
import LogIn from "./views/Login";
import AdminOverview from "./views/AdminOverview";
import CreateProfile from "./views/CreateDoctorProfile";
import EditRegularDoctorSchedule from "./views/EditRegularDoctorSchedule";
import EditPartTimeDoctorSchedule from "./views/EditPartTimeDoctorSchedule";
import EditClosedDays from "./views/EditClosedDays";
import Doctors from "./views/Doctors";
import Departments from "./views/ManageDepartment";
import EditSpecialDays from "./views/EditSpecialDays";
import Activate from "./views/Activate";
import ForgotPassword from "./views/ForgotPassword";
import ResetPassword from "./views/ResetPassword";
import Adminstrators from "./views/Adminstrators";
import ClosedDays from "./views/ClosedDays";
import ChangePassword from "./views/ChangePassword";
import Patients from "./views/Patients";
import EditTimeRange from "./views/EditTimeRange";
import CreateAdminProfile from "./views/CreateAdminProfile";
import DoctorDetail from "./views/DoctorDetail";
import EditDoctorProfile from "./views/EditDoctorProfile";
import Appointments from "./views/Appointments";
import Languages from "./views/ManageLanguages";
import Address from "./views/ManageAddress";
import AppoiintmentEditor from "./components/AppointmentEditor/AppoiintmentEditor.component";
import Services from "./views/ManageServices";

const routes = [
	{
		path: "/components-overview",
		layout: DefaultLayout,
		component: ComponentsOverview,
	},
	{
		path: "/",
		exact: true,
		layout: NormalLayout,
		component: () => <LogIn />,
	},
	{
		path: "/user/forgot-password",
		exact: true,
		layout: NormalLayout,
		component: () => <ForgotPassword />,
	},

	{
		path: "/user/reset/:id",
		exact: true,
		layout: NormalLayout,
		component: () => <ResetPassword />,
	},
	{
		path: "/admin/edit-doctor/:id",
		exact: true,
		layout: DefaultLayout,
		component: () => <EditDoctorProfile />,
	},
	{
		path: "/admin/change-password/",
		exact: true,
		layout: NormalLayout,
		component: () => <ChangePassword />,
	},
	{
		path: "/user/activate-account/:id",
		exact: false,
		layout: NormalLayout,
		component: () => <Activate />,
	},
	{
		path: "/admin/overview",
		layout: DefaultLayout,
		component: AdminOverview,
	},

	{
		path: "/admin/profile",
		layout: DefaultLayout,
		component: DocProfile,
	},

	{
		path: "/admin/create-profile",
		layout: DefaultLayout,
		component: CreateProfile,
	},

	{
		path: "/admin/create-admin-profile",
		layout: DefaultLayout,
		component: CreateAdminProfile,
	},
	{
		path: "/admin/departments",
		layout: DefaultLayout,
		component: Departments,
	},
	{
		path: "/admin/services",
		layout: DefaultLayout,
		component: Services,
	},
	{
		path: "/admin/doctors",
		layout: DefaultLayout,
		component: Doctors,
	},
	{
		path: "/admin/languages",
		layout: DefaultLayout,
		component: Languages,
	},
	{
		path: "/admin/address",
		layout: DefaultLayout,
		component: Address,
	},
	{
		path: "/admin/appointments",
		layout: DefaultLayout,
		component: Appointments,
	},

	{
		path: "/admin/doctors-detail/:id",
		layout: DefaultLayout,
		component: DoctorDetail,
	},

	{
		path: "/admin/edit-appointment/:doctor/:appointment",
		layout: DefaultLayout,
		component: AppoiintmentEditor,
	},
	{
		path: "/admin/patients",
		layout: DefaultLayout,
		component: Patients,
	},
	{
		path: "/admin/edit-regular",
		layout: DefaultLayout,
		component: EditRegularDoctorSchedule,
	},
	{
		path: "/admin/edit-part-time",
		layout: DefaultLayout,
		component: EditPartTimeDoctorSchedule,
	},
	{
		path: "/admin/closed-days",
		layout: DefaultLayout,
		component: EditClosedDays,
	},
	{
		path: "/admin/edit-special-days",
		layout: DefaultLayout,
		component: EditSpecialDays,
	},
	{
		path: "/errors",
		layout: DefaultLayout,
		component: Errors,
	},

	{
		path: "/admin/stock-log",
		layout: DefaultLayout,
		component: StockLog,
	},

	{
		path: "/admin/admins",
		layout: DefaultLayout,
		component: Adminstrators,
	},
	{
		path: "/admin/edit-time-range",
		layout: DefaultLayout,
		component: EditTimeRange,
	},

	{
		path: "/doc/overview",
		layout: DefaultLayout,
		component: DocOverview,
	},
	{
		path: "/doc/profile",
		layout: DefaultLayout,
		component: DocProfile,
	},
	{
		path: "/doc/schedules",
		layout: DefaultLayout,
		component: DoctorSchedule,
	},
	{
		path: "/doc/appointments",
		layout: DefaultLayout,
		component: DocAppointments,
	},
	{
		path: "/doc/special-days",
		layout: DefaultLayout,
		component: SpecialDays,
	},
	{
		path: "/doc/closed-days",
		layout: DefaultLayout,
		component: ClosedDays,
	},
];
export default routes;
