import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
	Button,
	Col,
	Form,
	FormInput,
	FormSelect,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from "shards-react";
import servicesApi from "../../api/services";
import "./EditService.styles.css";
const EditService = ({ toggle, open, fetch, setFetch, data }) => {
	const [services, setServices] = useState({
		title: { en: "", am: "" },
		about: {
			en: "",
			am: "",
		},
		category: "new-visit",
	});

	useEffect(() => {
		setServices({
			title: { en: data.titleEn, am: data.titleAm },
			about: {
				en: data.aboutEn,
				am: data.aboutAm,
			},
			category: "new-visit",
		});
	}, [data]);

	const handleUpdate = (e) => {
		e.preventDefault();
		toast.promise(
			servicesApi.updateOne(services, data._id, onSuccess, onFaileure),
			{
				pending: "proccessing ...",
			}
		);
	};

	const onSuccess = () => {
		setFetch(!fetch);
		setServices({
			title: { en: "", am: "" },
			about: {
				en: "",
				am: "",
			},
			category: "new-visit",
		});
		toggle();
	};
	const onFaileure = (error) => {
		console.log(error);
	};
	const handleChange = (e) => {
		if (e.target.name.slice(-2) === "en") {
			setServices({
				...services,
				[e.target.name.slice(0, -3)]: {
					am: services[e.target.name.slice(0, -3)].am,
					en: e.target.value,
				},
			});
		} else {
			setServices({
				...services,
				[e.target.name.slice(0, -3)]: {
					en: services[e.target.name.slice(0, -3)].en,
					am: e.target.value,
				},
			});
		}
	};
	return (
		<Modal open={open} toggle={toggle} centered={true} size='md'>
			<ModalHeader>Edit {data.title} Service</ModalHeader>
			<Form onSubmit={handleUpdate} method='post'>
				<ModalBody>
					<Col md='12' className='form-group'>
						<FormInput
							md='6'
							requ
							id='feDepartment'
							placeholder='Service Name'
							name='title.en'
							value={services.title.en}
							onChange={handleChange}
							lable='en'
						/>
					</Col>
					<Col md='12' className='form-group'>
						<FormInput
							md='6'
							requ
							id='feDepartment'
							placeholder='About the Service '
							name='about.en'
							value={services.about.en}
							onChange={handleChange}
							lable='en'
						/>
					</Col>
					<Col md='12' className='form-group'>
						<FormInput
							md='6'
							requ
							id='feDepartment'
							placeholder='አገልግሎት'
							name='title.am'
							value={services.title.am}
							onChange={handleChange}
							lable='en'
						/>
					</Col>
					<Col md='12' className='form-group'>
						<FormInput
							md='6'
							requ
							id='feDepartment'
							placeholder='ስለ አገልግሎቱ'
							name='about.am'
							value={services.about.am}
							onChange={handleChange}
							lable='en'
						/>
					</Col>
					<Col md='12' className='form-group'>
						<FormSelect
							name='time'
							onChange={(e) =>
								setServices({
									...services,
									category: e.target.value,
								})
							}
						>
							<option selected disabled>
								Select Category
							</option>

							<option value='new-visit'>New Visit</option>
							<option value='repeat-visit'>Repeat Visit</option>
						</FormSelect>
					</Col>
				</ModalBody>
				<ModalFooter>
					<Button
						theme='success'
						style={{ zIndex: "99999999999" }}
						type='submit'
					>
						Update
					</Button>
				</ModalFooter>
			</Form>
		</Modal>
	);
};

export default EditService;
