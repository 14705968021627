import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Container, Row } from "shards-react";
import departmentsApi from "../api/departments";
import PageTitle from "../components/common/PageTitle";
import CreateDepartment from "../components/departments/CreateDepartment";
import EditDepartment from "../components/EditDepartment/EditDepartment.component";
import Featching from "../components/featching/featching";
import { departmentsAdded } from "../store/departments-reducer";
import { formatDepartment } from "../utils/formatResponse";

const Departments = () => {
	const columns = [
		{
			title: "Department Name",
			field: "deptNameEn",
		},
		{
			title: "Status",
			field: "status",
		},
		{
			title: "Detail",
			field: "descriptionEn",
		},
	];
	const [data, setData] = useState([]);
	const [toEdit, setToEdit] = useState({});
	const [openEditor, setOpenEditor] = useState(false);

	const [fetch, setFetch] = useState(false);

	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setLoading(true);
		departmentsApi.getAll(fetchSuccess, onFaliure);
		// eslint-disable-next-line
	}, [fetch]);

	const onFaliure = (error) => {
		toast.error(error.message);
	};
	const fetchSuccess = (res) => {
		setLoading(false);
		dispatch(departmentsAdded(res.data.departments));
		setData(formatDepartment(res.data.departments));
	};

	const activateDepartment = (id) => {
		setLoading(true);
		departmentsApi.activate(id, activationSuccess, onFaliure);
	};
	const deactivateDepartment = (id) => {
		setLoading(true);
		departmentsApi.deactivate(
			id,
			activationSuccess,

			onFaliure
		);
	};
	const activationSuccess = () => {
		departmentsApi.getAll(fetchSuccess, onFaliure);
	};

	return (
		<Container fluid className='main-content-container px-4'>
			{/* Page Header */}
			<Row noGutters className='page-header py-4'>
				<PageTitle sm='4' title='Departments' className='text-sm-left' />
			</Row>

			<ToastContainer />
			<EditDepartment
				open={openEditor}
				toggle={() => setOpenEditor(!openEditor)}
				close={() => setOpenEditor(false)}
				setFetch={setFetch}
				fetch={fetch}
				toEdit={toEdit}
			/>
			<CreateDepartment setFetch={setFetch} fetch={fetch} />
			<Row>
				<Col>
					<Card small className='mb-4 overflow-hidden'>
						<CardHeader className='bg-dark'>
							<h6 className='m-0 text-white'>All Departments</h6>
						</CardHeader>
						<CardBody className='bg-light p-0 table__body'>
							<MaterialTable
								title={""}
								options={{
									exportButton: true,
									actionsColumnIndex: -1,
									search: true,
								}}
								columns={columns}
								data={data}
								actions={[
									{
										icon: "edit",
										tooltip: "Save User",
										onClick: (event, rowData) => {
											setOpenEditor(true);
											setToEdit(rowData);
											console.log("heniiii", rowData);
										},
									},
									(rowData) => ({
										icon: rowData.status === "Active" ? "dangerous" : "check",
										tooltip:
											rowData.status === "Active" ? "Deactivate" : "Activate",
										onClick: (event, rowData) => {
											rowData.status === "Active"
												? deactivateDepartment(rowData._id)
												: activateDepartment(rowData._id);
										},
										disabled: rowData.active,
									}),
								]}
							/>
							{loading && <Featching />}
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default Departments;
