import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import {
	Button,
	Card,
	Col,
	Container,
	FormSelect,
	Modal,
	Row,
} from "shards-react";
import doctorsApi from "../api/doctors";
import scheduleApi from "../api/schedules";
import specialDaysApi from "../api/specialDays";
import PageTitle from "../components/common/PageTitle";
import TimeSelector from "../components/TimeSelector/TimeSelector.component";
import { doctorsAdded } from "../store/doctors-reducer";
import { formatAMPM } from "../utils/formatAMPM";

const EditPartTimeDoctorSchedule = () => {
	let weekDays = [
		"Sunday",
		"Monday",
		"Tusday",
		"Wednsday",
		"Thursday",
		"Friday",
		"Saturday",
	];
	var d = new Date();
	var dayName = weekDays[d.getDay()];
	const [filter, setFilter] = useState({ day: dayName, index: d.getDay() });
	const [open, setOpen] = useState(false);
	const [shifts, setShifts] = useState([]);
	const [doctor, setDoctor] = useState(0);
	const [fetch, setFetch] = useState(false);

	const doctors = useSelector(
		(state) =>
			state.doctors &&
			state.doctors.filter((doc) => doc.employmentType !== "full-time")
	);

	const dispatch = useDispatch();

	useEffect(() => {
		doctorsApi.getAll(onFetchSuccess, onFaileure);

		// eslint-disable-next-line
	}, [filter, doctor, fetch]);

	const toggle = () => {
		setOpen(!open);
	};

	const onFetchSuccess = (res) => {
		dispatch(doctorsAdded(res.data.filter((user) => user.role !== "admin")));

		res.data
			.filter((doc) => doc.employmentType !== "full-time")
			[doctor].workingDaysWeekly.filter(
				(schedule) => schedule.day === filter.index
			)[0]
			? setShifts(
					res.data
						.filter((doc) => doc.employmentType !== "full-time")
						[doctor].workingDaysWeekly.filter(
							(schedule) => schedule.day === filter.index
						)
			  )
			: setShifts([]);
	};
	const handleChange = (newSlot) => {
		let temp = { ...shifts[0] };

		function isObjectEmpty(obj) {
			return Object.keys(obj).length === 0;
		}

		if (isObjectEmpty(temp)) {
			temp = {
				day: filter.index,
				suspense: false,
				availableHours: [newSlot],
			};
			setShifts([temp]);
		} else {
			temp = {
				...temp,
				availableHours: [...shifts[0].availableHours, newSlot],
			};
			setShifts([temp]);
		}
	};

	const handleDelete = (e) => {
		let filterd = shifts[0].availableHours.filter(
			(sh) => sh._id !== e.target.value
		);
		setShifts([{ ...shifts[0], availableHours: filterd }]);
	};

	const handleSave = () => {
		if (shifts[0].availableHours.length === 0) {
			toast.promise(
				specialDaysApi.deleteOne(
					{
						workingTimeId: shifts[0]._id,
						type: "weekly",
					},
					doctors[doctor]._id,
					onSuccess,
					onFaileure
				),
				{
					pending: "Saving ...",
				}
			);
		} else {
			toast.promise(
				scheduleApi.updatePartTime(
					shifts[0],
					doctors[doctor]._id,
					onSuccess,
					onFaileure
				),
				{
					pending: "Saving ...",
				}
			);
		}
	};

	const onFaileure = (error) => {
		console.log("This Failed : ", error);
	};

	const onSuccess = (data) => {
		setFetch(!fetch);
	};

	const changeTab = (e) => {
		setFilter({ day: e.target.name, index: parseInt(e.target.value) });
	};

	useEffect(() => {
		doctors[0] &&
		doctors[0].workingDaysWeekly.filter(
			(schedule) => schedule.day === filter.index
		)[0]
			? setShifts(
					doctors[0].workingDaysWeekly.filter(
						(schedule) => schedule.day === filter.index
					)
			  )
			: setShifts([]);
		// eslint-disable-next-line
	}, []);

	return (
		<Container fluid className='main-content-container px-4 pb-4'>
			<ToastContainer />
			<Row noGutters className='page-header py-4'>
				<PageTitle
					sm='4'
					title="Edit Part-time Doctor's Schedule"
					subtitle='Doctors'
					className='text-sm-left'
				/>
			</Row>
			<TimeSelector
				open={open}
				toggle={toggle}
				day={filter.day.toLocaleLowerCase()}
				handleChange={handleChange}
			/>
			<Row>
				{/* Editor */}
				<Col lg='12' md='12'>
					<Card small className='mb-3 mt-4 '>
						<Row
							style={{
								padding: "30px 50px",
								display: "grid",
								gridTemplateColumns: "1fr 1fr ",
								gridGap: "20px",
								height: "85px",
								paddingBottom: "0",
								paddingLeft: "35px",
							}}
							className='desktop'
						>
							<Col md='12' sm='12' className='form-group'>
								<FormSelect
									id='Doctors'
									onChange={(e) => setDoctor(e.target.value)}
								>
									{doctors.map((doc, index) => (
										<option key={index} value={index}>
											Dr. {doc.firstName.en} {doc.lastName.en}
										</option>
									))}
								</FormSelect>
							</Col>
						</Row>

						<Row
							className='mobile'
							style={{ padding: "0 30px", paddingTop: "20px" }}
						>
							<Row lg='8'>
								<Col md='8' sm='9' className='form-group'>
									<FormSelect
										id='Doctors'
										onChange={(e) => setDoctor(e.target.value)}
									>
										{doctors.map((doc, index) => (
											<option key={index} value={index}>
												Dr. {doc.firstName.en} {doc.lastName.en}
											</option>
										))}
									</FormSelect>
								</Col>
							</Row>
						</Row>

						<Row
							style={{
								padding: "30px 50px",
								display: "grid",
								gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr",
								gridGap: "20px",
								height: "85px",
								paddingBottom: "0",
							}}
							className='desktop'
						>
							{weekDays &&
								weekDays.map((day, index) => (
									<Button
										theme='primary'
										className='mb-2 mr-1  w100 hover-red'
										outline={filter.day !== day}
										value={index}
										onClick={changeTab}
										name={day}
										key={index}
									>
										{day}
									</Button>
								))}
						</Row>
						<Row className='mobile' style={{ padding: "0 30px" }}>
							<Row lg='8'>
								<Col md='8' sm='9' className='form-group'>
									<FormSelect id='Days' onChange={changeTab}>
										{weekDays &&
											weekDays.map((day, index) => (
												<option
													value={index}
													onClick={changeTab}
													name={day}
													key={index}
												>
													{day}
												</option>
											))}
									</FormSelect>
								</Col>
							</Row>
						</Row>
						<hr />
						<Row
							style={{
								padding: "10px 30px",
							}}
						>
							<Modal show />
							<Col>
								<h4>Time Slots</h4>
							</Col>
							<Col>
								<Button
									theme='success'
									style={{
										float: "right",
										width: "auto",
										margin: "0 auto!important",
									}}
									onClick={toggle}
								>
									Add New Slot
								</Button>
							</Col>
						</Row>
						<hr />
						<Row className='time-slot-container'>
							{shifts[0] ? (
								shifts[0].availableHours.map((schedule, index) => (
									<Button
										theme='primary'
										key={index}
										value={schedule._id}
										className='mb-2 mr-1 time-slots'
									>
										{`${formatAMPM(
											schedule.startHour.hour,
											schedule.startHour.minute
										)}
										-
										${formatAMPM(schedule.endHour.hour, schedule.endHour.minute)}`}
										<button
											value={schedule._id}
											className='close'
											onClick={handleDelete}
										>
											X
										</button>
									</Button>
								))
							) : (
								<Col>
									<h4>No Time Slots</h4>
								</Col>
							)}
						</Row>
						<hr />
						<Button theme='success' className='save' onClick={handleSave}>
							Save Changes
						</Button>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default EditPartTimeDoctorSchedule;
