import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
	Button,
	Col,
	Form,
	FormInput,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from "shards-react";
import departmentsApi from "../../api/departments";
import "./EditDepartment.styles.css";
const EditDepartment = ({ toggle, open, fetch, setFetch, toEdit }) => {
	const [department, setDepartment] = useState({
		deptName: {
			en: "",
			am: "",
		},
		deptDetail: {
			en: "",
			am: "",
		},
	});

	useEffect(() => {
		setDepartment({
			deptName: {
				en: toEdit.deptNameEn,
				am: toEdit.deptNameAm,
			},
			deptDetail: {
				en: toEdit.descriptionEn,
				am: toEdit.descriptionAm,
			},
		});
	}, [toEdit]);

	const handleSubmit = () => {
		toast.promise(
			departmentsApi.updateOne(department, toEdit._id, onSuccess, onFaileure),
			{
				pending: "Saving ...",
			}
		);
		toggle();
	};

	const onFaileure = (error) => {
		console.log("This Failed : ", error);
	};

	const onSuccess = (data) => {
		setFetch(!fetch);
	};
	const handleChange = (e) => {
		if (e.target.name.slice(-2) === "en") {
			setDepartment({
				...department,
				[e.target.name.slice(0, -3)]: {
					am: department[e.target.name.slice(0, -3)].am,
					en: e.target.value,
				},
			});
		} else {
			setDepartment({
				...department,
				[e.target.name.slice(0, -3)]: {
					en: department[e.target.name.slice(0, -3)].en,
					am: e.target.value,
				},
			});
		}
	};

	return (
		<Modal open={open} toggle={toggle} centered={true} size='md'>
			<ModalHeader>Edit {toEdit.name} Department</ModalHeader>
			<Form onSubmit={handleSubmit} method='post'>
				<ModalBody>
					<Col md='12' className='form-group'>
						<FormInput
							md='6'
							requ
							id='feDepartment'
							placeholder='Department Name'
							name='deptName.en'
							value={department.deptName.en}
							onChange={handleChange}
							required
							lable='en'
						/>
					</Col>
					<Col md='12' className='form-group'>
						<FormInput
							md='6'
							requ
							id='feDepartment'
							placeholder='Department Name'
							name='deptDetail.en'
							value={department.deptDetail.en}
							onChange={handleChange}
							required
							lable='en'
						/>
					</Col>
					<Col md='12' className='form-group'>
						<FormInput
							md='6'
							requ
							id='feDepartment'
							placeholder='የቡድን ስም'
							name='deptName.am'
							value={department.deptName.am}
							onChange={handleChange}
							required
							lable='en'
						/>
					</Col>
					<Col md='12' className='form-group'>
						<FormInput
							md='6'
							requ
							id='feDepartment'
							placeholder='የቡድን መግለጫ'
							name='deptDetail.am'
							value={department.deptDetail.am}
							onChange={handleChange}
							required
							lable='en'
						/>
					</Col>
				</ModalBody>
				<ModalFooter>
					<Button
						theme='success'
						style={{ zIndex: "99999999999" }}
						type='submit'
					>
						Update
					</Button>
				</ModalFooter>
			</Form>
		</Modal>
	);
};

export default EditDepartment;
