import React, { useEffect, useState } from "react";

import "react-phone-number-input/style.css";
import { useSelector } from "react-redux";
import { Col, Form, ListGroup, ListGroupItem, Row } from "shards-react";
import departmentsApi from "../../api/departments";
import { formatDate } from "../../utils/formatDate";

const DoctorDetailCard = ({ data, id }) => {
	const [departments, setDepartments] = useState([]);
	useEffect(() => {
		departmentsApi.getAll((data) => setDepartments(data.data.departments));
	}, []);

	const locations = useSelector((state) => state.location);
	const languages = useSelector((state) => state.language);

	return (
		<ListGroup flush>
			<ListGroupItem className='p-3'>
				<Row>
					<Col>
						{data && (
							<Form>
								<Row form>
									{/* First Name */}
									<Col md='6' className='form-group'>
										<label htmlFor='feProductName'>Full Name</label>
										<span
											id='feProductName'
											placeholder='Product Name'
											name='itemName'
											className='form-control'
										>
											{data.firstName.en} {data.lastName.en}
										</span>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='feProductName'>Username</label>
										<span
											id='feProductName'
											placeholder='Product Name'
											name='itemName'
											className='form-control'
										>
											{data.userName}
										</span>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='feProductName'>Email</label>
										<span
											id='feProductName'
											placeholder='Product Name'
											name='itemName'
											className='form-control'
										>
											{data.email}
										</span>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='feProductName'>Gender</label>
										<span
											id='feProductName'
											placeholder='Product Name'
											name='itemName'
											className='form-control'
										>
											{data.gender}
										</span>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='feProductName'>Birth Date</label>
										<span
											id='feProductName'
											placeholder='Product Name'
											name='itemName'
											className='form-control'
										>
											{formatDate(new Date(data.birthDate))}
										</span>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='feProductName'>Phone Number</label>
										<span
											id='feProductName'
											placeholder='Product Name'
											name='itemName'
											className='form-control'
										>
											{data.phoneNumber}
										</span>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='feProductName'>Specialization</label>
										<span
											id='feProductName'
											placeholder='Product Name'
											name='itemName'
											className='form-control'
										>
											{data.specialization}
										</span>
									</Col>

									<Col md='6' className='form-group'>
										<label htmlFor='feProductName'>Employment Type</label>
										<span
											id='feProductName'
											placeholder='Product Name'
											name='itemName'
											className='form-control'
										>
											{data.employmentType}
										</span>
									</Col>
									{departments.length &&
										departments.find((dep) => dep._id === data.department) && (
											<Col md='6' className='form-group'>
												<label htmlFor='feProductName'>Department</label>
												<span
													id='feProductName'
													placeholder='Product Name'
													name='itemName'
													className='form-control'
												>
													{
														departments.find(
															(dep) => dep._id === data.department
														).deptName
													}
												</span>
											</Col>
										)}
									<Col md='6' className='form-group'>
										<label htmlFor='feProductName'>Role</label>
										<span
											id='feProductName'
											placeholder='Product Name'
											name='itemName'
											className='form-control'
										>
											{data.role}
										</span>
									</Col>
									{/* <Col md='6' className='form-group'>
										<label htmlFor='feProductName'>Doctor created in</label>
										<span
											id='feProductName'
											placeholder='Product Name'
											name='itemName'
											className='form-control'
										>
											{formatDate(new Date(data.createdAt))}
										</span>
									</Col> */}
									<Col md='6' className='form-group'>
										<label htmlFor='feProductName'>Languages</label>

										<span
											id='feProductName'
											placeholder='Product Name'
											name='itemName'
											className='form-control'
											style={{ display: "flex" }}
										>
											{languages
												.filter((lan) =>
													data.languages.join().includes(lan._id)
												)
												.map((y) => {
													return y.label;
												})
												.join(", ")}
										</span>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='feProductName'>Location</label>
										<span
											id='feProductName'
											placeholder='Product Name'
											name='itemName'
											className='form-control'
										>
											{locations.find((lan) => data.address === lan._id).label}
										</span>
									</Col>
									<Col md='12' className='form-group'>
										<label htmlFor='feProductName'>Bio</label>
										<span
											id='feProductName'
											placeholder='Product Name'
											name='itemName'
											className='form-control'
										>
											{data.bio}
										</span>
									</Col>

									{/* Password createdAt*/}
								</Row>
							</Form>
						)}
					</Col>
				</Row>
			</ListGroupItem>
		</ListGroup>
	);
};
export default DoctorDetailCard;
